<template>
  <router-view/>
</template>
<script>
    export default {
    name: 'juzgadosIndex'
    }
</script>

<style scoped>

</style>
